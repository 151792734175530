import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Radio,Fielset,Box wrap=function",
        "componentName": "Radio,Fielset,Box",
        "wrap": "function"
      }}>{`() => {
  const values = ['Video', 'Killed', 'The', 'Radio', 'Star'];
  const [checkedValue, setCheckedValue] = React.useState(values[0]);
  const isChecked = value => value === checkedValue;

  return (
    <Fieldset label="The Buggles">
      {values.map(value => (
        <Box key={value} mb={5}>
          <Radio
            name="buggles"
            id={\`label-\${value}\`}
            value={value}
            label={value}
            checked={isChecked(value)}
            onChange={e => setCheckedValue(e.target.value)}
          />
        </Box>
      ))}
    </Fieldset>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Radio" mdxType="Props" />
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Adds an action or extra information to your item, mostly used for links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Radio
  name="action"
  id="action"
  value="action"
  label="Label"
  action={<LinkText href="#">LinkText</LinkText>}
/>
`}</code></pre>
    <h2 {...{
      "id": "checked",
      "style": {
        "position": "relative"
      }
    }}>{`Checked`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Make a Radio selected by passing the `}<inlineCode parentName="p">{`checked`}</inlineCode>{` prop, only one item with the same
name can be checked at a time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const values = ['Check', 'It', 'Out'];
  const [checkedValue, setCheckedValue] = React.useState(values[0]);
  const isChecked = value => value === checkedValue;

  return (
    <Fieldset label="Check!">
      {values.map(value => (
        <Box key={value} mb={5}>
          <Radio
            name="checked"
            id={\`label-\${value}\`}
            value={value}
            label={value}
            checked={isChecked(value)}
            onChange={e => setCheckedValue(e.target.value)}
          />
        </Box>
      ))}
    </Fieldset>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Marking a Radio as `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, will disable all interaction with the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Radio name="disabled" id="disabled" value="disabled" label="Label" disabled />
`}</code></pre>
    <h2 {...{
      "id": "error",
      "style": {
        "position": "relative"
      }
    }}>{`Error`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To convey an error to the user, you can set the `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Radio name="error" id="error" value="error" label="Label" error />
`}</code></pre>
    <h2 {...{
      "id": "labehidden",
      "style": {
        "position": "relative"
      }
    }}>{`LabeHidden`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To meet accessibility requirements, every Radio should have an associated label.
We do support the ability to visually hide the label when the Radio's purpose is
clear.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`<Radio
  name="labeHidden"
  id="labelHidden"
  value="hi"
  label="Label is still required"
  labelHidden
/>
`}</code></pre>
    <h2 {...{
      "id": "labelplacement",
      "style": {
        "position": "relative"
      }
    }}>{`LabelPlacement`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The positioning of the label against the Radio itself. The full label will move
position (also action, message & optionalLabel).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const values = ['left', undefined, 'right'];
  const [checkedValue, setCheckedValue] = React.useState(values[0]);
  const isChecked = value => value === checkedValue;

  return (
    <Fieldset label="LabelPlacement">
      {values.map(value => (
        <Box key={value} mb={5}>
          <Radio
            name="labelPlacement"
            id={\`label-\${value}\`}
            value={\`\${value}\`}
            label={\`\${value}\`}
            checked={isChecked(\`\${value}\`)}
            onChange={e => setCheckedValue(e.target.value)}
            labelPlacement={value}
          />
        </Box>
      ))}
    </Fieldset>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "labelprops",
      "style": {
        "position": "relative"
      }
    }}>{`LabelProps`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`You can visually change the label of the Radio. For more info about the
possibilities, check the `}<a parentName="p" {...{
        "href": "/components/text/code/"
      }}>{`Text component's`}</a>{`
documentation (Label inherits the Text).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Radio
  mb={5}
  name="labelProps"
  id="labelProps"
  label="Label"
  labelPlacement="left"
  labelProps={{
    color: 'primaryBase',
    decoration: 'line-through',
    fontFamily: 'primary',
    fontWeight: 'regular',
    size: 'Heading2',
    textTransform: 'capitalize',
  }}
/>
`}</code></pre>
    <h2 {...{
      "id": "message",
      "style": {
        "position": "relative"
      }
    }}>{`Message`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Give additional information to the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Radio
  name="message"
  id="message"
  value="message"
  label="Label"
  message="Message can be set to provide additional information."
/>
`}</code></pre>
    <h2 {...{
      "id": "optionallabel",
      "style": {
        "position": "relative"
      }
    }}>{`OptionalLabel`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`If the optionalLabel is not set, the Radio is required.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Radio
  name="optionalLabel"
  id="optionalLabel"
  value="optionalLabel"
  label="Label"
  optionalLabel="I'm optional"
/>
`}</code></pre>
    <h2 {...{
      "id": "required",
      "style": {
        "position": "relative"
      }
    }}>{`Required`}</h2>
    <p><inlineCode parentName="p">{`default: true`}</inlineCode></p>
    <p>{`By default the Radio is required. You can change this by providing an
optionalLabel or passing false to the required prop. We encourage you to use the
optionalLabel prop, this provides additional feedback to the user!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Radio
  name="required"
  id="required"
  value="required"
  required={false}
  label="Label"
/>
`}</code></pre>
    <h2 {...{
      "id": "value",
      "style": {
        "position": "relative"
      }
    }}>{`Value`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Use value for controlled Radios.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const values = ['What', 'About', 'Value'];
  const [checkedValue, setCheckedValue] = React.useState(values[0]);
  const isChecked = value => value === checkedValue;

  return (
    <Fieldset label="Value">
      {values.map(value => (
        <Box key={value} mb={5}>
          <Radio
            name="checked"
            id={\`label-\${value}\`}
            value={value}
            label={value}
            checked={isChecked(value)}
            onChange={e => setCheckedValue(e.target.value)}
          />
        </Box>
      ))}
    </Fieldset>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      